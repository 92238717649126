* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.login {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.login form {
    background-color: white;
    padding: 3rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.login form input {
    padding: 0.5rem 1rem;
    font-size: 16px;
    border-radius: 3px;
    outline: none;
    border: 1px solid black;
}

.login form button {
    padding: 0.5rem 1rem;
    width: 100%;
    
    color: white;
    font-size: 16px;
    border: none;
    margin-top: 1rem;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s;
}
