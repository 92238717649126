@import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);

/* Bootstrap Icons */
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");

body {
    background-color: #f5f9fc;
}




.selected {
    background-color: #5c60f5;
    color: white;
}




.suggestions {
    position: absolute;
    top: 90px;
    z-index: 100000 !important;
    background-color: white;
    width: 270px;
}
ul {
    display: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid black;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
}

ul::-webkit-scrollbar {
    
}

ul.has-suggestions {
    display: block;
}
ul li {
    padding: 10px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
}
ul li:hover {
    background-color: #5c60f5;
    color: white;
}
